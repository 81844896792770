import React from "react"
import Page from "../components/common/page"

const PageTemplate = ({ pageContext }) => {
    const { data } = pageContext
    
    return (
        <Page data={data} />
    )
}

export default PageTemplate
